import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"

import "./post.css";

export default function Post({ data }) {
  const post = data.markdownRemark
  const tags = (post.frontmatter.tags || []).map((tag, i) => <span className="badge" key={`post-tag-${i}`}>{tag}</span>)
  return (
    <Layout>
      <div>
        <h1>{post.frontmatter.title}</h1>
        {tags}
        <br/>
        <br/>
        <hr/>
        <div dangerouslySetInnerHTML={{ __html: post.html }}/>
        <Link to="/">Home</Link>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        tags
      }
    }
  }
`